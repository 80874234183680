import { IEnvironment } from '@environments/classes/IEnvironment';

export const environment: IEnvironment = {
  name: 'DEV',
  production: false,
  apiUrl: 'https://api.nyugdijas.whc.attrecto-dev.com/api',
  authStorageStrategy: 'cookie',
  cookieDomain: '.whc.attrecto-dev.com',
  backendVersion: 'v1',
  assetUrl: 'https://api.nyugdijas.whc.attrecto-dev.com',
  atsType: 'pensioner',
  appInsights: {
    key: 'e7b336ff-3ad1-4eb8-9a3f-63424c71e6a3',
    applicationName: 'ats.pensioner',
  },
  countryShortName: 'hu',
  exportCount: 100,
  powerBiBaseUrl: 'https://app.powerbi.com/',
};
